import { Module } from 'vuex';
import { get, post } from '@/services/api';
import { AxiosResponse } from 'axios';
import { SoundbyteData } from '@/types';
import { GetUserSoundbytesResponse } from '@/services/ApiTypes';
import handleError from '@/utils/handleError';

type SoundbyteState = {
  soundBytes: SoundbyteData[];
  fetching: boolean;
  totalSoundBytes: number;
};

const soundBytestore: Module<SoundbyteState, any> = {
  namespaced: true,
  state: {
    soundBytes: [],
    fetching: false,
    totalSoundBytes: 0,
  },

  getters: {
    fetching: (state) => state.fetching,
    soundBytes: (state) => state.soundBytes,
    totalSoundBytes: (state) => state.totalSoundBytes,
  },

  mutations: {
    toggleFetching(state, payload: boolean) {
      state.fetching = payload;
    },
    setSoundBytes(state, soundBytes: SoundbyteData[]) {
      state.soundBytes = soundBytes;
    },
    setTotalSoundBytes(state, totalSoundBytes: number) {
      state.totalSoundBytes = totalSoundBytes;
    },
    updateLikeUnlikeSoundByte(state, { soundByteId, isLiked, likeCount }) {
      for (let index = 0; index < state.soundBytes.length; index++) {
        const element = state.soundBytes[index];
        if (soundByteId === element.id) {
          const count = element.likes_count || 0;
          state.soundBytes[index].is_liked = isLiked;
          state.soundBytes[index].likes_count = likeCount || (isLiked ? +count + 1 : +count - 1);
        }
      }
    },
    delete(state, { soundByteId }) {
      state.soundBytes = state.soundBytes.filter((s) => s.id !== soundByteId);
    },
  },
  actions: {
    async fetchMySoundByte({ commit }, params) {
      commit('toggleFetching', true);
      try {
        const response = (await get('/user/soundbytes', {
          params,
        })) as AxiosResponse<GetUserSoundbytesResponse>;
        const { data } = response || {};
        commit('setSoundBytes', data || []);
        commit('setTotalSoundBytes', (data || []).length);
      } catch (error: any) {
        handleError(error);
      } finally {
        commit('toggleFetching', false);
      }
    },
    async likeUnlikeSoundByte({ commit }, { soundByteId, isLiked }) {
      try {
        await post(`/soundbytes/${soundByteId}/${isLiked ? 'like' : 'unlike'}`);
        return true;
      } catch (error: any) {
        handleError(error);
        return false;
      }
    },
  },
};

export default soundBytestore;
