<template>
  <router-view />
</template>

<script setup lang="ts">
import { apiInstance } from './services/api';
import store from './store';

const checkStore = () => {
  const token = store?.state?.auth?.token || '';
  if (token) {
    apiInstance.defaults.headers.Authorization = `Bearer ${token}`;
  }
};

checkStore();
</script>
