/* eslint-disable import/extensions */
import { createApp } from 'vue';
import ToastPlugin from 'vue-toast-notification';
import VueDatePicker from '@vuepic/vue-datepicker';

import App from './App.vue';
import router from './router';
import store from './store';

import './registerServiceWorker';

import '@/assets/css/bootstrap.min.css';
import '@/assets/css/select2.min.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import '@vuepic/vue-datepicker/dist/main.css';
import '@/assets/css/root.css';
import '@/assets/css/general.css';
import '@/assets/css/styles.css';
import '@/assets/css/responsive.css';
import '@/assets/css/developer.css';

// import '@/assets/js/general.js';
// import '@/assets/js/horizontal.js';

import '@/assets/js/bootstrap.min.js';
import '@/assets/js/select2.min.js';

createApp(App)
  .use(store)
  .use(router)
  .use(ToastPlugin)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app');
