import { isAxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';

const toast = useToast();

export default (error: any) => {
  if (typeof error === 'string') {
    toast.error(error);
  } else if (isAxiosError(error)) {
    const { message } = error.response?.data || {};
    if (message) {
      toast.error(message);
    } else {
      toast.error(error.message);
    }
  } else if (error?.message) {
    toast.error(error.message);
  } else {
    toast.error('Something went wrong. Please try after sometime.');
  }
};
