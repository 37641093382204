export function incrementGuestPageViews() {
  const limit = parseInt(process.env.VUE_APP_GUEST_BROWSE_LIMIT || '5', 10);
  let views = parseInt(localStorage.getItem('guestPageViews') || '0', 10);

  views += 1;
  localStorage.setItem('guestPageViews', views.toString());

  return views;
}

export function hasReachedGuestPageViewLimit() {
  const limit = parseInt(process.env.VUE_APP_GUEST_BROWSE_LIMIT || '5', 10);
  const views = parseInt(localStorage.getItem('guestPageViews') || '0', 10);

  return views >= limit;
}

export function resetGuestPageViews() {
  localStorage.removeItem('guestPageViews');
}
