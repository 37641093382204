import SecureLS from 'secure-ls';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
import modal from './modules/modal';
import player from './modules/player';
import audioFile from './modules/audioFile';
import collection from './modules/collections';
import category from './modules/categories';
import soundByte from './modules/soundbyte';

const ls = new SecureLS({ isCompression: false, encryptionSecret: 'voicevault2k24' });

export default createStore<any>({
  modules: {
    auth,
    modal,
    player,
    audioFile,
    collection,
    category,
    soundByte,
  },
  plugins: [
    createPersistedState({
      paths: ['auth'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
