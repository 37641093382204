import { Module } from 'vuex';
import { deleteMethod, get, post } from '@/services/api';
import { AxiosResponse, isAxiosError } from 'axios';
import { useToast } from 'vue-toast-notification';
import { GetCategoryResponse } from '@/services/ApiTypes';
import { Category } from '@/types';

const toast = useToast();

type CategoryState = {
  allCategory: Category[];
  fetching: boolean;
};

const handleError = (error: any) => {
  if (isAxiosError(error)) {
    const { message } = error.response?.data || {};
    if (message) {
      toast.error(message);
    } else {
      toast.error(error.message);
    }
  } else if (error?.message) {
    toast.error(error.message);
  } else {
    toast.error('Something went wrong. Please try after sometime.');
  }
};

const categoryStore: Module<CategoryState, any> = {
  namespaced: true,
  state: {
    allCategory: [],
    fetching: false,
  },
  getters: {
    allCategory: (state) => state.allCategory,
    fetching: (state) => state.fetching,
  },
  mutations: {
    toggleFetching(state, payload: boolean) {
      state.fetching = payload;
    },
    setAllCategories(state, allCategory: Category[]) {
      state.allCategory = allCategory;
    },
  },
  actions: {
    async fetchAllCategories({ commit }, params) {
      commit('toggleFetching', true);
      try {
        const response = (await get('/categories', {
          params,
        })) as AxiosResponse;
        commit('setAllCategories', response.data.data);
      } catch (error: any) {
        handleError(error);
        commit('setAllCategories', []);
      } finally {
        commit('toggleFetching', false);
      }
    },
  },
};

export default categoryStore;
