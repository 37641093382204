import { Module } from 'vuex';
import { deleteMethod, get, post } from '@/services/api';
import { AxiosResponse } from 'axios';
import { useToast } from 'vue-toast-notification';
import handleError from '@/utils/handleError';
import { CreateCollection } from '@/services/ApiTypes';
import { SoundbyteData } from '@/types';
import { getFormatedCollection } from '@/utils/helperfunctions';

const toast = useToast();

type Collection = {
  id: number;
  name: string;
  is_private: boolean;
  tags: string[];
  cover: {
    url: string;
    thumb: string;
  } | null;
  created_at?: string;
  updated_at?: string;
  is_following?: boolean;
  followers_count: number;
};

type CollectionState = {
  totalCollections: number;
  collections: Collection[];
  fetchStatus: {
    [key: string]: boolean;
  };
  currentCollection: Collection | undefined;
  fetchingSoundBytes: boolean;
  currentCollectionSoundBytes: SoundbyteData[];
};

const formatCollection = (collection: any) => ({
  ...collection,
  cover: collection.cover
    ? {
        thumb: collection.cover?.conversions?.thumb,
        url: collection.cover?.url || collection.cover?.original_url,
      }
    : null,
});

const collection: Module<CollectionState, any> = {
  namespaced: true,
  state: {
    totalCollections: 0,
    collections: [],
    fetchStatus: {
      deleting: false,
      fetching: false,
      following: false,
    },
    currentCollection: undefined,
    fetchingSoundBytes: false,
    currentCollectionSoundBytes: [],
  },
  getters: {
    totalCollections: (state) => state.totalCollections,
    collections: (state) => state.collections,
    deleting: (state) => state.fetchStatus.deleting,
    fetching: (state) => state.fetchStatus.fetching,
    following: (state) => state.fetchStatus.following,
    fetchingSoundBytes: (state) => state.fetchingSoundBytes,
    getCurrentCollection: (state) => state.currentCollection,
    getSoundBytes: (state) => state.currentCollectionSoundBytes,
  },
  mutations: {
    setTotalCollections(state, payload: number) {
      state.totalCollections = payload;
    },
    incrementTotalCollections(state) {
      state.totalCollections += 1;
    },
    decrementTotalCollections(state) {
      state.totalCollections -= 1;
    },
    setCollections(state, payload: any) {
      state.collections = payload.map(formatCollection);
    },
    addCollection(state, payload: any) {
      state.collections.push(formatCollection(payload));
    },
    updateCollection(state, updatedCollection: any) {
      const index = state.collections.findIndex((c) => c.id === updatedCollection.id);
      if (index !== -1) {
        state.collections[index] = formatCollection(updatedCollection);
      } else {
        state.collections.push(formatCollection(updatedCollection));
        state.totalCollections += 1;
      }
    },
    updateFollowing(state, { collectionId, isFollowing }) {
      const index = state.collections.findIndex((c) => c.id === collectionId);
      if (index !== -1) {
        state.collections[index].is_following = isFollowing;
        state.collections[index].followers_count += isFollowing ? 1 : -1;
      }
    },
    setCurrentCollection(state, collectionId: number) {
      state.currentCollection = state.collections.find((c) => c.id === collectionId);
    },
    setCurrentCollectionSoundBytes(state, soundBytes: SoundbyteData[]) {
      state.currentCollectionSoundBytes = soundBytes;
    },
    clearCurrentCollection(state) {
      state.currentCollection = undefined;
      state.currentCollectionSoundBytes = [];
    },
    deleteCollection(state, id: number) {
      state.collections = state.collections.filter((c) => c.id !== id);
    },
    removeSoundByte(state, id: number) {
      state.currentCollectionSoundBytes = state.currentCollectionSoundBytes.filter(
        (c) => c.id !== id,
      );
    },
    toggleDeleting(state, payload: boolean) {
      state.fetchStatus.deleting = payload;
    },
    toggleFollowing(state, payload: boolean) {
      state.fetchStatus.following = payload;
    },
    toggleFetching(state, payload: boolean) {
      state.fetchStatus.fetching = payload;
    },
    toggleFetchingSoundBytes(state, payload: boolean) {
      state.fetchingSoundBytes = payload;
    },
  },
  actions: {
    async fetchCollections({ commit }, params) {
      commit('toggleFetching', true);
      try {
        const response = (await get('/creators/collections', {
          params,
        })) as AxiosResponse;
        commit('setCollections', response.data.data.map(getFormatedCollection));
        commit('setTotalCollections', response.data.data.length);
      } catch (error: any) {
        handleError(error);
        commit('setCollections', []);
        commit('setTotalCollections', 0);
      } finally {
        commit('toggleFetching', false);
      }
    },
    async fetchCollectionById({ commit }, collectionId) {
      commit('toggleFetching', true);
      try {
        const response = (await get(`/collections/${collectionId}`)) as AxiosResponse;
        commit('updateCollection', getFormatedCollection(response.data));
        commit('setCurrentCollection', +collectionId);
      } catch (error: any) {
        handleError(error);
      } finally {
        commit('toggleFetching', false);
      }
    },
    async fetchCollectionSoundBytes({ commit }, { collectionId, ...params }) {
      commit('toggleFetchingSoundBytes', true);
      try {
        const response = (await get(`/collections/${collectionId}/soundbytes`, {
          params,
        })) as AxiosResponse;
        commit('setCurrentCollectionSoundBytes', response.data.data);
        commit('toggleFetchingSoundBytes', false);
      } catch (error: any) {
        handleError(error);
        commit('toggleFetchingSoundBytes', false);
      }
    },
    async createCollection({ commit }, payload: FormData) {
      try {
        const response = (await post('/collections', payload, {
          headers: { 'Content-Type': 'multipart/form-data;' },
        })) as AxiosResponse<CreateCollection>;
        commit('addCollection', response.data);
        commit('incrementTotalCollections');
        toast.success('Collection created.');
        return response.data;
      } catch (error: any) {
        handleError(error);
        return false;
      }
    },
    async updateCollection({ commit }, payload: FormData) {
      try {
        const response = (await post(`/collections/${payload.get('id')}`, payload, {
          headers: { 'Content-Type': 'multipart/form-data;' },
        })) as AxiosResponse;
        commit('updateCollection', response.data);
        toast.success('Collection updated.');
        return response.data;
      } catch (error: any) {
        handleError(error);
        return false;
      }
    },
    async deleteCollection({ commit }, id: number) {
      try {
        commit('toggleDeleting', true);
        await deleteMethod(`/collections/${id}`);
        commit('deleteCollection', id);
        commit('decrementTotalCollections');
        commit('toggleDeleting', false);
        toast.success('Collection deleted.');
        return true;
      } catch (error: any) {
        handleError(error);
        commit('toggleDeleting', false);
        return false;
      }
    },
    async followCollection({ state, commit }, { collectionId, isFollowing }) {
      try {
        commit('toggleFollowing', true);
        await post(`/collections/${collectionId}/${isFollowing ? 'unfollow' : 'follow'}`);
        commit('updateFollowing', { collectionId, isFollowing: !isFollowing });
        if (state.currentCollection?.id === collectionId) {
          commit('setCurrentCollection', collectionId);
        }
        commit('toggleFollowing', false);
        toast.success(`Collection ${isFollowing ? 'un' : ''}followed.`);
        return true;
      } catch (error: any) {
        handleError(error);
        commit('toggleFollowing', false);
        return false;
      }
    },
    async removeSoundByte({ commit }, { collectionId, soundByteId }) {
      try {
        commit('toggleDeleting', true);
        await deleteMethod(`/collections/${collectionId}/soundbytes`, {
          data: {
            soundbytes: [soundByteId],
          },
        });
        commit('removeSoundByte', soundByteId);
        commit('toggleDeleting', false);
        toast.success('Soundbyte removed.');
        return true;
      } catch (error: any) {
        handleError(error);
        commit('toggleDeleting', false);
        return false;
      }
    },
  },
};

export default collection;
