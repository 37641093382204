import { Module } from 'vuex';

type AudioFileState = {
  file: File | null;
};

const auth: Module<AudioFileState, any> = {
  namespaced: true,
  state: {
    file: null,
  },
  getters: {
    file: (state) => state.file,
  },
  mutations: {
    updateFile(state, file: File | null) {
      state.file = file;
    },
  },
};

export default auth;
