import { Module } from 'vuex';
import { resetGuestPageViews } from '@/utils/guestTracking';

type User = {
  id: number;
  name: string;
  email: string;
  email_verified_at?: string | null;
  profile_photo_path?: string | null;
  created_at?: string;
  updated_at?: string;
};

type AuthState = {
  token: string;
  user: User;
};

const auth: Module<AuthState, any> = {
  namespaced: true,
  state: {
    token: '',
    user: {} as User,
  },
  getters: {
    token: (state) => state.token,
    user: (state) => state.user,
  },
  mutations: {
    updateToken(state, token: string) {
      state.token = token;
      if (token) {
        // Reset the guest page views when the user logs in
        resetGuestPageViews();
      }
    },
    updateUser(state, user: User) {
      state.user = user;
    },
  },
};

export default auth;
