import { Module } from 'vuex';

type ModalState = {
  openFileUploadSuccessModal: boolean;
  openSoundbyteDeleteConfirmationModal: boolean;
  soundbyteDeleteConfirmationId: number | null;
  deleteConfirmationId: number | null;
  openDeleteConfirmationModal: boolean;
};

const modal: Module<ModalState, any> = {
  namespaced: true,
  state: {
    openFileUploadSuccessModal: false,
    openSoundbyteDeleteConfirmationModal: false,
    soundbyteDeleteConfirmationId: null,
    openDeleteConfirmationModal: false,
    deleteConfirmationId: null,
  },
  getters: {
    openFileUploadSuccessModal: (state) => state.openFileUploadSuccessModal,
    openSoundbyteDeleteConfirmationModal: (state) => state.openSoundbyteDeleteConfirmationModal,
    soundbyteDeleteConfirmationId: (state) => state.soundbyteDeleteConfirmationId,
    deleteConfirmationId: (state) => state.deleteConfirmationId,
    openDeleteConfirmationModal: (state) => state.openDeleteConfirmationModal,
  },
  mutations: {
    toggleOpenFileUploadSuccessModal(state, payload: boolean) {
      state.openFileUploadSuccessModal = payload;
    },
    toggleOpenSoundbyteDeleteConfirmationModal(state, payload: boolean) {
      state.openSoundbyteDeleteConfirmationModal = payload;
    },
    toggleOpenDeleteConfirmationModal(state, payload: boolean) {
      state.openDeleteConfirmationModal = payload;
    },
    updateSoundbyteDeleteConfirmationId(state, payload: number | null) {
      state.soundbyteDeleteConfirmationId = payload;
    },
    updateDeleteConfirmationId(state, payload: number | null) {
      state.deleteConfirmationId = payload;
    },
  },
};

export default modal;
