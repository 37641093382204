import { UserAvatar } from '@/services/ApiTypes';
import { DebouncedFunction } from '@/types';
import moment from 'moment';

const getFormatedDuration = (sec: number) => {
  const sec_num = sec;
  let hours: number | string = Math.floor(sec_num / 3600);
  let minutes: number | string = Math.floor((sec_num - hours * 3600) / 60);
  let seconds: number | string = sec_num - hours * 3600 - minutes * 60;

  hours = Number(Number(hours).toFixed(0));
  minutes = Number(Number(minutes).toFixed(0));
  seconds = Number(Number(seconds).toFixed(0));

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
};

const getFormatedDate = (date: string, customFormat?: string) => {
  if (!date) return '';
  const dateFormat = customFormat || 'DD/MM/YYYY hh:mm A';
  const momentDate = moment.utc(date);
  return momentDate.format(dateFormat);
};

const getFormatedCommentTime = (date: string) => {
  if (!date) return '';
  const dateTime = moment.utc(date);
  if (moment.utc().isSame(dateTime, 'date')) {
    return dateTime.fromNow();
  }
  return dateTime.format('MMM DD YYYY');
};

const getAudioTime = (seconds: number) => {
  const second = Math.floor(seconds);
  const minutes = Number((Math.floor(second) / 60).toFixed(0));
  const hours = Number((Math.floor(minutes) / 60).toFixed(0));
  return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${second > 9 ? second : `0${second}`}`;
};

type AvatarType = 'main' | 'xl' | 'md' | 'lg' | 'sm' | 'thumb';

const getUserAvatar = (avatar: UserAvatar | undefined, avatarType: AvatarType = 'main') => {
  if (avatar && avatar.conversions) {
    if (avatar?.conversions) {
      if (avatarType === 'main') {
        return avatar.url || '';
      }
      return avatar?.conversions[avatarType];
    }
  }
  return '';
};

const getFormatedCollection = (collection: any) => ({
  ...collection,
  cover: collection.cover
    ? {
        thumb: collection.cover?.conversions?.thumb,
        url: collection.cover?.url || collection.cover?.original_url,
      }
    : null,
});

const debounce = (func: DebouncedFunction, wait: number) => {
  let timeout: any;
  return function (...args: any[]) {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), wait);
  };
};

export {
  getAudioTime,
  getUserAvatar,
  getFormatedDate,
  getFormatedDuration,
  getFormatedCommentTime,
  getFormatedCollection,
  debounce,
};
