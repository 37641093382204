import mitt, { EventType, Handler } from 'mitt';

type Events = {
  'pause-player': string;
  'play-player': string;
  'clear-player': string;
};

const emitter = mitt();

export default {
  on: <Key extends keyof Events>(type: Key, handler: Handler<Events[Key]>) =>
    emitter.on(type as EventType, handler as Handler<unknown>),
  off: <Key extends keyof Events>(type: Key, handler?: Handler<Events[Key]>) =>
    emitter.off(type as '*', handler as Handler<unknown>),
  emit: <Key extends keyof Events>(type: Key, event?: Events[Key]) => emitter.emit(type, event),
  all: () => emitter.all,
};
