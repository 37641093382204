import eventBus from '@/services/eventBus';
import { SoundbyteData } from '@/types';
import { Module } from 'vuex';

type PlayerState = {
  currentDuration: number;
  soundDuration: number;
  allSounds: SoundbyteData[];
  isPlaying: boolean;
  isRepeatEnable: boolean;
  isShuffleEnable: boolean;
  isBuffring: boolean;
  currentSoundData: SoundbyteData | null;
  volume: number;
  isMuted: boolean;
};

const player: Module<PlayerState, any> = {
  namespaced: true,
  state: {
    volume: 1.0,
    isMuted: false,
    isBuffring: false,
    isRepeatEnable: false,
    isShuffleEnable: false,
    isPlaying: false,
    currentDuration: 0,
    soundDuration: 0,
    currentSoundData: null,
    allSounds: [],
  },
  getters: {
    currentDuration: (state) => state.currentDuration,
    soundDuration: (state) => state.soundDuration,
    isPlaying: (state) => state.isPlaying,
    isRepeatEnable: (state) => state.isRepeatEnable,
    isShuffleEnable: (state) => state.isShuffleEnable,
    isBuffring: (state) => state.isBuffring,
    volume: (state) => state.volume,
    isMuted: (state) => state.isMuted,
    currentSoundData: (state) => state.currentSoundData,
    isLastSoundByte: (state) =>
      state.currentSoundData &&
      state.allSounds &&
      state.allSounds[state.allSounds.length - 1]?.id === state.currentSoundData?.id,
    isFirstSoundByte: (state) =>
      state.currentSoundData &&
      state.allSounds &&
      state.allSounds[0]?.id === state.currentSoundData?.id,
  },
  mutations: {
    updateCurrentDuration(state, payload: number) {
      state.currentDuration = payload;
    },
    updateSoundDuration(state, payload: number) {
      state.soundDuration = payload;
    },
    updateIsPlaying(state, flag: boolean) {
      state.isPlaying = flag;
    },
    updateIsRepeatEnable(state, flag: boolean) {
      state.isRepeatEnable = flag;
    },
    updateIsShuffleEnable(state, flag: boolean) {
      state.isShuffleEnable = flag;
    },
    updateIsBuffring(state, flag: boolean) {
      state.isBuffring = flag;
    },
    updateVolume(state, volume: number) {
      state.volume = volume;
    },
    updateIsMuted(state, flag: boolean) {
      state.isMuted = flag;
    },
    updateCurrentSoundByteData(state, soundbyteData: SoundbyteData | null) {
      state.currentSoundData = soundbyteData;
    },
    updateSoundByte(state, soundbyteData: SoundbyteData) {
      state.allSounds = state.allSounds.map((s) => (s.id === soundbyteData.id ? soundbyteData : s));
      if (state.currentSoundData?.id === soundbyteData.id) {
        state.currentSoundData = soundbyteData;
      }
    },
    playAll(state, soundbytes: SoundbyteData[]) {
      const soundbyteData = soundbytes.length > 0 ? soundbytes[0] : null;
      state.volume = 1.0;
      state.isMuted = false;
      state.soundDuration = 0;
      state.isBuffring = false;
      state.currentDuration = 0;
      state.allSounds = soundbytes;
      state.isRepeatEnable = false;
      state.isShuffleEnable = false;
      state.currentSoundData = soundbyteData;
      if (soundbyteData) {
        state.isPlaying = true;
      }
    },
  },
  actions: {
    setNextSoundbyte({ state }) {
      const currentIndex = state.currentSoundData
        ? state.allSounds.findIndex((s) => s.id === state.currentSoundData?.id)
        : -1;
      const nxtInd = currentIndex + 1;
      let nextIndex = state.isRepeatEnable && nxtInd >= state.allSounds.length ? 0 : nxtInd;
      if (state.isShuffleEnable) {
        nextIndex = Math.floor(Math.random() * state.allSounds.length);
      }
      if (state.allSounds.length > 0 && state.allSounds.length > nextIndex) {
        const soundbyteData = state.allSounds[nextIndex];
        state.currentSoundData = soundbyteData;
        state.currentDuration = 0;
        state.soundDuration = 0;
        if (soundbyteData) {
          state.isPlaying = true;
        }
      }
    },
    setPreviousSoundbyte({ state }) {
      const currentIndex = state.currentSoundData
        ? state.allSounds.findIndex((s) => s.id === state.currentSoundData?.id)
        : -1;
      let nextIndex = currentIndex - 1;
      if (state.isShuffleEnable) {
        nextIndex = Math.floor(Math.random() * state.allSounds.length);
      }
      if (nextIndex > -1 && state.allSounds.length > 0 && state.allSounds.length > nextIndex) {
        const soundbyteData = state.allSounds[nextIndex];
        state.currentSoundData = soundbyteData;
        state.currentDuration = 0;
        state.soundDuration = 0;
        if (soundbyteData) {
          state.isPlaying = true;
        }
      }
    },
    onUpdateSoundbyte({ state }, { id, allSoundBytes }) {
      state.allSounds = allSoundBytes;
      const index = state.allSounds.findIndex((s) => s.id === id);
      const soundbyteData = state.allSounds[index];

      if (state.currentSoundData && state.currentSoundData?.id === id) {
        if (state.isPlaying) {
          eventBus.emit('pause-player');
        } else {
          eventBus.emit('play-player');
        }
      } else if (soundbyteData) {
        state.currentSoundData = soundbyteData;
        state.currentDuration = 0;
        state.soundDuration = 0;
        if (soundbyteData) {
          state.isPlaying = true;
        }
      }
    },
  },
};

export default player;
