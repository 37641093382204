import store from '@/store';
import $ from 'jquery';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { incrementGuestPageViews } from '@/utils/guestTracking';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'AppContainer',
    component: () => import('@/container/AppContainer.vue'),
    redirect: '/auth/login',
    children: [
      {
        path: '',
        name: 'MainContainer',
        component: () => import('@/container/MainContainer.vue'),
        redirect: {
          name: 'HomeView',
        },
        children: [
          {
            path: 'home',
            name: 'HomeView',
            meta: { title: 'Home' },
            component: () => import('@/views/HomePage.vue'),
          },
          {
            path: 'upload',
            name: 'UploadView',
            meta: { title: 'Add Soundbyte' },
            component: () => import('@/views/UploadPage.vue'),
          },
          {
            path: 'record-soundbyte',
            name: 'RecordSoundbyteView',
            meta: { title: 'Record Soundbyte' },
            component: () => import('@/views/RecordSoundbyte.vue'),
          },
          {
            path: 'upload-details',
            name: 'UploadDetailsView',
            meta: { title: 'Upload Details' },
            component: () => import('@/views/UploadDetailsPage.vue'),
          },
          {
            path: 'upload-details/:id',
            name: 'UpdateSoundbyteDetailsView',
            meta: {
              title: 'Edit Soundbyte Details',
            },
            component: () => import('@/views/UploadDetailsPage.vue'),
          },
          {
            path: 'profile',
            name: 'VaultContainer',
            component: () => import('@/views/Vault/VaultContainer.vue'),
            children: [
              {
                path: ':userId',
                redirect: {
                  name: 'MySoundbyteView',
                },
                children: [
                  {
                    path: 'soundbytes',
                    name: 'MySoundbyteView',
                    meta: { title: 'Soundbytes' },
                    component: () => import('@/views/Vault/MySoundbytePage.vue'),
                  },
                  {
                    path: 'collections',
                    name: 'CollectinsView',
                    meta: { title: 'Collections' },
                    component: () => import('@/views/Vault/MyCollectionsPage.vue'),
                  },
                  {
                    path: 'following',
                    name: 'FollowingView',
                    meta: { title: 'Following' },
                    component: () => import('@/views/Vault/MyFollowingPage.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'profile',
            name: 'ProfileContainerRaw',
            children: [
              {
                path: ':userId',
                name: 'ProfileViewRaw',
                children: [
                  {
                    path: 'collections/:collectionId',
                    name: 'CollectionSoundbytesView',
                    meta: { title: 'Collection Soundbytes' },
                    component: () => import('@/views/Vault/CollectionSoundbytesPage.vue'),
                  },
                  {
                    path: 'edit',
                    name: 'EditProfileView',
                    meta: {
                      title: 'My Profile',
                    },
                    component: () => import('@/views/Profile/EditProfile.vue'),
                  },
                  {
                    path: 'liked',
                    name: 'SoundbyteLiked',
                    meta: {
                      title: 'Liked SoundBytes',
                    },
                    component: () => import('@/views/Vault/SoundbyteLiked.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'soundbytes/:id',
            name: 'SoundbyteComments',
            meta: {
              title: 'Comments',
            },
            component: () => import('@/views/Soundbytes/SoundbyteComments.vue'),
          },
          {
            path: 'categories',
            name: 'Categories',
            meta: {
              title: 'Categories',
            },
            children: [
              {
                path: ':categoryId',
                name: 'CategoryView',
                meta: { title: 'Category Soundbytes' },
                component: () => import('@/views/Categories/CategorySoundbytes.vue'),
              },
            ],
          },
        ],
      },
      {
        path: '/auth',
        name: 'AuthContainer',
        component: () => import('@/container/AuthContainer.vue'),
        redirect: '/auth/login',
        children: [
          {
            path: 'login',
            name: 'LoginView',
            meta: {
              title: 'Login',
            },
            component: () => import('@/views/Auth/LoginView.vue'),
          },
          {
            path: 'signup',
            name: 'SignupView',
            meta: {
              title: 'Sign Up',
            },
            component: () => import('@/views/Auth/SignupView.vue'),
          },
          {
            path: 'forgot-password',
            name: 'ForgotPasswordView',
            meta: {
              title: 'Forgot Password',
            },
            component: () => import('@/views/Auth/ForgotPasswordView.vue'),
          },
          {
            path: 'verify-email',
            name: 'VerifyEmailView',
            meta: {
              title: 'Verify Email',
            },
            component: () => import('@/views/Auth/VerifyEmailView.vue'),
          },
          {
            path: 'password-reset/:token',
            name: 'ResetPasswordView',
            meta: {
              title: 'Reset Password',
            },
            component: () => import('@/views/Auth/ResetPasswordView.vue'),
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
  routes,
});

// Global navigation guard to set the page title
router.beforeEach(async (to) => {
  $('.header-search-box').trigger('blur');
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta.title} | Voice Vault`;
  } else {
    document.title = 'Voice Vault'; // Fallback title
  }

  const isAuthenticate = !!(store?.state?.auth?.token || '');

  // Allow access to the Home page, profile pages, and specific routes without authentication
  const publicPaths = [
    'HomeView',
    'ProfileViewRaw',
    'MySoundbyteView',
    'CollectinsView',
    'FollowingView',
    'CategoryView', // Allow access to category view
    'CollectionSoundbytesView', // Allow access to collection soundbytes view
    'SoundbyteComments', // Allow access to soundbytes view without login
  ];

  if (to.name && publicPaths.includes(to.name as string)) {
    if (!isAuthenticate) {
      incrementGuestPageViews(); // Just increment the view count here
    }
    return true;
  }

  // Check if the user is authenticated
  if (!isAuthenticate && !to.path.includes('/auth/')) {
    // Redirect to login if not authenticated
    return { name: 'LoginView' };
  }

  // Redirect authenticated users away from /auth pages to home
  if (isAuthenticate && to.path.includes('/auth/')) {
    return { name: 'HomeView' };
  }

  return true;
});

export default router;
