import axios, { AxiosRequestConfig } from 'axios';

const apiInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

const get = (url = '', data?: AxiosRequestConfig) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await apiInstance.get(`${url}`, data);
      resolve(res);
    } catch (error: any) {
      reject(error);
    }
  });

const post = (url = '', data = {}, requestConfig?: AxiosRequestConfig) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await apiInstance.post(`${url}`, data, requestConfig);
      resolve(res);
    } catch (error: any) {
      reject(error);
    }
  });

const put = (url = '', data = {}, requestConfig?: AxiosRequestConfig) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await apiInstance.put(`${url}`, data, requestConfig);
      resolve(res);
    } catch (error: any) {
      reject(error);
    }
  });

const deleteMethod = (url = '', requestConfig?: AxiosRequestConfig) =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await apiInstance.delete(`${url}`, requestConfig);
      resolve(res);
    } catch (error: any) {
      reject(error);
    }
  });

export { get, post, put, deleteMethod, apiInstance };
